<template>
  <screen-grid>
    <screen-card
      :title="$t('frontOffices.id.content.section.generalData.title')"
      display="grid"
      :is-loading="isLoading"
    >
      <template v-slot:body>
        <screen-block
          :title="$t('frontOffices.id.content.section.generalData.label.features')"
          icon="category"
          :has-data="Array.isArray(currentFrontOffice.featureList) && currentFrontOffice.featureList.length > 0"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editFeatures"
        >
          <template v-slot:data>
            <span
              v-for="(feature, idx) in currentFrontOffice.featureList"
              :key="`feature-${idx}`"
              class="front-office-content__feature"
            >
              {{ getTitle(feature) }}<template v-if="idx < currentFrontOffice.featureList.length - 1">, </template>
            </span>
          </template>
        </screen-block>
        <screen-block
          :title="$t('frontOffices.id.content.section.generalData.label.locationMaxPictures')"
          icon="library_photo"
          :has-data="typeof currentFrontOffice.locationMaxPictures === 'number'"
          :data="currentFrontOffice.locationMaxPictures"
          ratio="1-3"
          :is-loading="isLoading"
          @click="
            editSimpleField('locationMaxPictures', 'text', 'number', false, {
              min: 0,
              max: 6,
              step: 1,
            })
          "
        />
        <screen-block
          :title="$t('frontOffices.id.content.section.generalData.label.hotMaxPictures')"
          icon="library_photo"
          :has-data="typeof currentFrontOffice.hotMaxPictures === 'number'"
          :data="currentFrontOffice.hotMaxPictures"
          ratio="1-3"
          :is-loading="isLoading"
          @click="
            editSimpleField('hotMaxPictures', 'text', 'number', false, {
              min: 0,
              max: 6,
              step: 1,
            })
          "
        />
      </template>
    </screen-card>
    <screen-card :title="$t('frontOffices.id.content.section.eventSlot.title')" display="grid" :is-loading="isLoading">
      <template v-slot:body>
        <screen-block
          :title="$t('frontOffices.id.content.section.eventSlot.label.slots')"
          icon="category"
          :has-data="
            Array.isArray(currentFrontOffice.dynamicContentSlot) && currentFrontOffice.dynamicContentSlot.length > 0
          "
          ratio="1-1"
          :is-loading="isLoading"
          @click="editEventSlot('dynamicContentSlot')"
        >
          <template v-slot:data>
            <span
              v-for="(dynamicContentSlot, idx) in currentFrontOffice.dynamicContentSlot"
              :key="`feature-${idx}`"
              class="front-office-content__feature"
            >
              {{ dynamicContentSlot.label
              }}<template v-if="idx < currentFrontOffice.dynamicContentSlot.length - 1">, </template>
            </span>
          </template>
        </screen-block>
      </template>
    </screen-card>
    <screen-card
      :title="$t('frontOffices.id.content.section.event.title')"
      ratio="1-2"
      display="grid"
      :is-loading="isLoading"
      :helper="$t('locations.id.locator.section.event.helper')"
    >
      <template v-slot:body>
        <template v-if="isLoading">
          <screen-flux v-for="n in 2" :key="`event-skeleton-${n}`" :is-loading="isLoading" ratio="1-2" />
        </template>
        <template v-else>
          <screen-flux
            v-for="(event, idx) in currentFrontOffice.event"
            :key="`event-${idx}`"
            :id="event.id"
            :title="event.title"
            :picture="event.picture"
            :description="event.description"
            :link="event.link"
            icon="link"
            :from="event.from"
            :to="event.to"
            :tags="event.tags"
            :locale="currentFrontOffice.localeCode"
            ratio="1-2"
            @click="editMedia('event', event)"
            :draggable="true"
            :is-draggable="true"
            :is-drag-over-me="isDragOverMe(idx, true, 1)"
            @drop.native="onDrop($event)"
            @dragover.prevent.native="dragover(idx, 1)"
            @dragstart.native="startDrag($event, event.id, idx, 1)"
            @dragend.native="endDrag"
            class="front-office-content--is-editable-draggable"
            :class="[
              {
                'front-office-content--is-editable-draggable__active':
                  dragged.start && dragged.index === idx && dragged.grpIdx === 1,
              },
            ]"
          />
          <div
            class="front-office-content__no-data"
            v-if="!currentFrontOffice.event || currentFrontOffice.event.length === 0"
            v-html="
              $t('common.label.noDataAlone', {
                locale: currentFrontOffice.localeName,
              })
            "
          ></div>
        </template>
        <div class="front-office-content__actions">
          <skeleton-input v-if="isLoading" width="228px" />
          <ui-button
            v-else
            button="primary"
            variant="influence"
            icon="add_post"
            :label="$t('frontOffices.id.content.section.event.button.addEvent')"
            @click="editMedia('event')"
          />
        </div>
      </template>
    </screen-card>
    <screen-card
      :title="$t('frontOffices.id.content.section.headline.title')"
      display="grid"
      ratio="1-2"
      :is-loading="isLoading"
      :helper="$t('locations.id.locator.section.event.helper')"
    >
      <template v-slot:body>
        <template v-if="isLoading">
          <screen-flux v-for="n in 3" :key="`event-skeleton-${n}`" :is-loading="isLoading" ratio="1-2" />
        </template>
        <template v-else>
          <screen-flux
            v-for="(hotPicture, idx) in currentFrontOffice.hotPictures"
            :key="`hot-picture-${idx}`"
            :id="hotPicture.id"
            :title="hotPicture.name"
            :picture="hotPicture.picture_url"
            :description="hotPicture.description"
            :link="hotPicture.url"
            icon="link"
            ratio="1-2"
            @click="editHotPicture('hotPictures', hotPicture)"
            :is-draggable="true"
            :is-drag-over-me="isDragOverMe(idx, true, 2)"
            @drop.native="onDrop($event, null, 'hotPictures')"
            @dragover.prevent.native="dragover(idx, 2)"
            @dragstart.native="startDrag($event, hotPicture.id, idx, 2, 2)"
            @dragend.native="endDrag"
            class="front-office-content--is-editable-draggable"
            :class="[
              {
                'front-office-content--is-editable-draggable__active':
                  dragged.start && dragged.index === idx && dragged.grpIdx === 2,
              },
            ]"
          />
          <div
            class="front-office-content__no-data"
            v-if="!currentFrontOffice.hotPictures || currentFrontOffice.hotPictures.length === 0"
            v-html="
              $t('common.label.noDataAlone', {
                locale: currentFrontOffice.localeName,
              })
            "
          ></div>
        </template>
        <div class="front-office-content__actions">
          <skeleton-input v-if="isLoading" width="228px" />
          <ui-button
            v-else
            button="primary"
            variant="influence"
            icon="add_photo"
            :label="$t('frontOffices.id.content.section.headline.button.addHeadline')"
            :disabled="
              currentFrontOffice.hotPictures &&
              currentFrontOffice.hotPictures.length >= currentFrontOffice.hotMaxPictures
            "
            @click="editHotPicture('hotPictures')"
          />
        </div>
      </template>
    </screen-card>
    <screen-card
      :title="$t('frontOffices.id.content.section.photos.title')"
      ratio="1-1"
      display="grid"
      :is-loading="isLoading"
    >
      <template v-slot:body>
        <template v-if="isLoading">
          <screen-flux v-for="n in 2" :key="`photo-skeleton-${n}`" :is-loading="isLoading" ratio="1-3" />
        </template>
        <template v-else>
          <screen-flux
            v-for="(picture, idx) in currentFrontOffice.formatted_pictures"
            :id="picture.id"
            :key="`picture-${idx}`"
            :picture="picture.picture"
            ratio="1-3"
            @click="editPicture('locationPictures', picture.picture)"
            :is-draggable="true"
            :is-drag-over-me="isDragOverMe(idx, true, 3)"
            @drop.native="onDrop($event, null, 'formatted_pictures')"
            @dragover.prevent.native="dragover(idx, 3)"
            @dragstart.native="startDrag($event, picture.id, idx, 3, 3)"
            @dragend.native="endDrag"
            class="front-office-content--is-editable-draggable"
            :class="[
              {
                'front-office-content--is-editable-draggable__active':
                  dragged.start && dragged.index === idx && dragged.grpIdx === 3,
              },
            ]"
          />
          <div
            class="front-office-content__no-data"
            v-if="!currentFrontOffice.formatted_pictures || currentFrontOffice.formatted_pictures.length === 0"
            v-html="
              $t('common.label.noDataAlone', {
                locale: currentFrontOffice.localeName,
              })
            "
          ></div>
        </template>
        <div class="front-office-content__actions">
          <skeleton-input v-if="isLoading" width="228px" />
          <ui-button
            v-else
            button="primary"
            variant="influence"
            icon="add_photo"
            :label="$t('frontOffices.id.content.section.photos.button.addPhoto')"
            :disabled="
              currentFrontOffice.locationPictures &&
              currentFrontOffice.locationPictures.length >= currentFrontOffice.locationMaxPictures
            "
            @click="editPicture('locationPictures')"
          />
        </div>
      </template>
    </screen-card>
    <screen-card
      class="front-office-content__SEO"
      :title="$t('frontOffices.id.content.section.seoContent.title')"
      display="grid"
      :is-loading="isLoading"
    >
      <template v-slot:header-right>
        <skeleton-line v-if="isLoading" height="18px" width="32px" margin-left="16px" />
        <ui-dropdown
          v-if="currentClient.locationTypes && currentClient.locationTypes.length > 1"
          class="front-office-content__SEO__dropdown"
          id="dropdown-location-type"
          v-model="locationType"
          :options="currentClient.locationTypes"
          :placeholder="$t('frontOffices.id.content.section.seoContent.locationType')"
        />
      </template>
      <template v-slot:body>
        <screen-block
          :title="$t('frontOffices.id.content.section.seoContent.label.locationDescription')"
          icon="text"
          :has-data="
            currentFrontOffice.locationDescription != null &&
            typeof currentFrontOffice.locationDescription[locationType] === 'string' &&
            currentFrontOffice.locationDescription[locationType] !== ''
          "
          :data="currentFrontOffice.locationDescription ? currentFrontOffice.locationDescription[locationType] : ''"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSeoContent('locationDescription')"
        />
        <screen-block
          :title="
            $t('frontOffices.id.content.section.seoContent.label.locationMetaDescription', { type: locationType })
          "
          icon="text"
          :has-data="
            currentFrontOffice.locationMetaDescription != null &&
            typeof currentFrontOffice.locationMetaDescription[locationType] === 'string' &&
            currentFrontOffice.locationMetaDescription[locationType] !== ''
          "
          :data="
            currentFrontOffice.locationMetaDescription ? currentFrontOffice.locationMetaDescription[locationType] : ''
          "
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSeoContent('locationMetaDescription')"
        />
        <screen-block
          :title="$t('frontOffices.id.content.section.seoContent.label.locationMetaTitle')"
          icon="text"
          :has-data="
            currentFrontOffice.locationMetaTitle != null &&
            typeof currentFrontOffice.locationMetaTitle[locationType] === 'string' &&
            currentFrontOffice.locationMetaTitle[locationType] !== ''
          "
          :data="currentFrontOffice.locationMetaTitle ? currentFrontOffice.locationMetaTitle[locationType] : ''"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSeoContent('locationMetaTitle')"
        />
      </template>
    </screen-card>
  </screen-grid>
</template>

<script>
import UiButton from '@/components/UI/Button.vue'
import UiDropdown from '@/components/UI/Dropdown.vue'
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import ScreenBlock from '@/components/Screen/Block.vue'
import ScreenFlux from '@/components/Screen/Flux.vue'
import SkeletonInput from '@/components/Skeleton/Input.vue'
import SkeletonLine from '@/components/Skeleton/Line.vue'
import reverse from 'lodash/reverse'
import DragMixin from '@/mixins/drag.mixin'

export default {
  name: 'FrontOfficeContent',
  components: {
    UiButton,
    UiDropdown,
    ScreenCard,
    ScreenBlock,
    ScreenGrid,
    ScreenFlux,
    SkeletonInput,
    SkeletonLine,
  },
  mixins: [DragMixin],
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentFrontOffice: {
      type: Object,
      required: true,
    },
    currentClient: {
      type: Object,
      required: true,
    },
    currentClientFeatureList: {
      type: Array,
      required: false,
      default: () => [],
    },
    frontOfficeFeatureList: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      locationType: '',
      reverse,
    }
  },
  mounted() {
    this.locationType = this.currentClient.locationTypes[0]
  },
  methods: {
    getTitle(feature) {
      let result = feature
      if (this.frontOfficeFeatureList !== null) {
        this.frontOfficeFeatureList.filter(featureListItem =>
          featureListItem.key === feature ? (result = featureListItem.title) : null
        )
      }
      return result
    },
    editSimpleField(objKey, type, mode, required = false, number = null, options = []) {
      this.$emit('editSimpleField', {
        objKey,
        parameters: {
          type,
          mode,
          required,
          number,
          options,
        },
      })
    },
    editEventSlot(objKey) {
      this.$emit('editDynamicContentSlot', {
        objKey,
        parameters: {
          options: this.currentFrontOffice.dynamicContentSlot,
        },
      })
    },
    editFeatures() {
      const { featureList, featureListException } = this.currentFrontOffice
      this.$emit('editFeatures', {
        activeList: featureList || [],
        exceptions: featureListException || {},
        features: this.currentClientFeatureList,
        parameters: {
          mode: 'frontoffice',
        },
      })
    },
    editMedia(type, media) {
      this.$emit('editMedia', {
        media: media || { frontOfficeId: this.currentFrontOffice.id },
        parameters: {
          type,
        },
      })
    },
    editHotPicture(type, media) {
      this.$emit('editMedia', {
        media: media
          ? {
              id: media.id,
              picture: media.picture_url,
              alt: media.alt,
              link: media.url,
              title: media.name,
              titleSeo: media.titleSeo,
            }
          : { frontOfficeId: this.currentFrontOffice.id },
        parameters: {
          type,
        },
      })
    },
    editPicture(type, media) {
      this.$emit('editMedia', {
        media: media
          ? {
              id: 1,
              picture: media,
            }
          : { frontOfficeId: this.currentFrontOffice.id },
        parameters: {
          type,
        },
      })
    },
    editSeoContent(objKey) {
      this.$emit('editSeoContent', {
        objKey,
        parameters: {
          type: this.locationType,
          options: this.currentClient.locationTypes,
          helpers: {
            title: this.$t('locations.id.locator.section.seoContent.helpers.info'),
            subtitle: this.$t('locations.id.locator.section.seoContent.helpers.variables'),
            location: this.$t('locations.id.locator.section.seoContent.helpers.location'),
            city: this.$t('locations.id.locator.section.seoContent.helpers.city'),
            shortest: this.$t('locations.id.locator.section.seoContent.helpers.shortest'),
            longest: this.$t('locations.id.locator.section.seoContent.helpers.longest'),
            characters: this.$t('locations.id.locator.section.seoContent.helpers.characters'),
            recommended: this.$t('locations.id.locator.section.seoContent.helpers.recommended'),
            help: this.$t('locations.id.locator.section.seoContent.helpers.help'),
            to: this.$t('locations.id.locator.section.seoContent.helpers.to'),
            char: this.$t('locations.id.locator.section.seoContent.helpers.char'),
            max: this.$t('locations.id.locator.section.seoContent.helpers.max'),
            moreVariables: this.$t('locations.id.locator.section.seoContent.helpers.moreVariables'),
          },
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.front-office-content {
  &--is-editable-draggable {
    &:hover {
      outline: 2px solid $blue-royal;
      box-shadow: 0 0 16px rgba(0, 0, 0, 0.4);
      cursor: grab;
    }

    &__active {
      transition: all $transition-duration-very-fast $transition-effect-default;
      opacity: 0.1;
    }
  }

  &__feature {
    text-transform: capitalize;
  }

  &__no-data {
    margin: $gutter-mobile / 2;
    color: var(--text-color-alt);
    font-style: italic;

    @media (min-width: $screen-sm) {
      margin: $gutter-tablet / 2;
    }

    @media (min-width: $screen-xl) {
      margin: $gutter-desktop / 2;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: $gutter-mobile / 2;
    margin-bottom: $gutter-mobile / 2;
    width: 100%;

    @media (min-width: $screen-sm) {
      margin-top: $gutter-tablet / 2;
      margin-bottom: $gutter-tablet / 2;
    }

    @media (min-width: $screen-xl) {
      margin-top: $gutter-desktop / 2;
      margin-bottom: $gutter-desktop / 2;
    }
  }

  &__SEO {
    position: relative;
    align-items: self-start;

    @media (min-width: $screen-sm) {
      align-items: normal;
    }
    &__dropdown {
      position: absolute;
      top: 0;
      right: 0;
      margin: $gutter-mobile;
      width: auto;

      @media (min-width: $screen-sm) {
        margin: $gutter-tablet;
      }

      @media (min-width: $screen-xl) {
        margin: $gutter-desktop;
      }
    }
  }
}
</style>

<template>
  <screen-grid>
    <screen-card
      class="front-office-event__group"
      v-for="(eventGroup, idx) in currentFrontOffice.dynamicContent"
      :key="`group-event-${idx}`"
      :title="eventGroup.name"
      ratio="1-2"
      display="grid"
      :is-loading="isLoading"
      :no-margin="true"
    >
      <template v-slot:title>
        <div class="front-office-event__group__title-label">{{ `${eventGroup.name} (${eventGroup.id})` }}</div>
      </template>
      <template v-slot:header-right>
        <skeleton-input v-if="isLoading" height="44px" width="44px" />
        <ui-button
          v-else-if="blockToDelete !== idx"
          :label="$t('common.button.delete')"
          button="cta"
          variant="error"
          icon="delete"
          :icon-only="true"
          @click="askForDelete(idx)"
        />
      </template>
      <template v-slot:body>
        <div v-if="blockToDelete === idx" class="front-office-event__group__confirm">
          <span>{{ $t('frontOffices.id.content.section.event.confirm.label') }}</span>
          <div>
            <ui-button
              class="front-office-event__group__confirm__icon"
              :label="$t('common.button.no')"
              button="secondary"
              variant="error"
              icon="close"
              :icon-only="true"
              @click.prevent="blockToDelete = ''"
            />
            <ui-button
              class="front-office-event__group__confirm__icon"
              :label="$t('common.button.yes')"
              button="secondary"
              variant="success"
              icon="check"
              :icon-only="true"
              @click.prevent="removeGroup('dynamicContent', eventGroup)"
            />
          </div>
        </div>
        <template v-if="isLoading">
          <screen-flux v-for="n in 2" :key="`event-skeleton-${n}`" :is-loading="isLoading" ratio="1-2" />
        </template>
        <template v-else>
          <screen-flux
            v-for="(event, eventIdx) in eventGroup.events"
            :key="`event-${eventIdx}`"
            :id="event.id"
            :title="event.title"
            :picture="event.picture"
            :description="event.description"
            :link="event.link"
            icon="link"
            :from="event.from"
            :to="event.to"
            :tags="event.tags"
            :locale="currentFrontOffice.localeCode"
            ratio="1-2"
            @click="editMedia('dynamicContent', eventGroup, event)"
            :draggable="true"
            :is-draggable="true"
            :is-drag-over-me="isDragOverMe(eventIdx, true, idx)"
            @drop.native="onDrop($event, eventGroup.id)"
            @dragover.prevent.native="dragover(eventIdx, idx)"
            @dragstart.native="startDrag($event, event.id, eventIdx, idx, eventGroup.id)"
            @dragend.native="endDrag"
            class="front-office-event--is-editable-draggable"
            :class="[
              {
                'front-office-event--is-editable-draggable__active':
                  dragged.start && dragged.index === eventIdx && dragged.grpIdx === idx,
              },
            ]"
          />
          <div
            class="front-office-event__group__no-data"
            v-if="(!eventGroup.events || eventGroupLength(eventGroup) === 0) && blockToDelete !== idx"
            v-html="
              $t('common.label.noDataAlone', {
                locale: currentFrontOffice.localeName,
              })
            "
          ></div>
        </template>
        <div class="front-office-event__group__actions">
          <skeleton-input v-if="isLoading" width="228px" />
          <ui-button
            :class="
              isOdd(eventGroupLength(eventGroup))
                ? `front-office-event__group__actions__button${isMobile ? '' : '--full'}`
                : `front-office-event__group__actions__button${isMobile ? '' : '--demi'}`
            "
            v-else-if="blockToDelete !== idx"
            button="primary"
            variant="influence"
            icon="add_post"
            :label="$t('frontOffices.id.content.section.event.button.addEvent')"
            @click="addMedia('dynamicContent', eventGroup)"
          />
        </div>
      </template>
    </screen-card>

    <screen-card
      class="front-office-event__create"
      ratio="1-2"
      display="grid"
      :bodyFullHeight="true"
      :no-margin="true"
      :is-loading="isLoading"
    >
      <template v-slot:body>
        <div class="front-office-event__create__content">
          <skeleton-input v-if="isLoading" margin-top="100px" margin-left="auto" margin-right="auto" width="50%" />
          <div class="front-office-event__create__content__slot" v-else>
            <ui-dropdown
              class="front-office-event__create__content__slot__dropdown"
              :no-absolute="true"
              v-model="slotKeySelect"
              :options="slotAvailables"
              :placeholder="$t('modal.dynamicContentSlot.dropdown.label')"
              label="label"
              track-by="id"
              :dropdown-label="$t('modal.dynamicContentSlot.dropdown.label')"
              :show-label="true"
            />
            <ui-button
              :disabled="isUpdating || slotKeySelect === null"
              :loaderActive="isUpdating"
              class="front-office-event__create__content__slot__button"
              button="secondary"
              variant="influence"
              icon="add_post"
              :label="$t('frontOffices.id.content.section.event.button.addGroupEvent')"
              @click="createNewGroup('dynamicContent')"
            />
          </div>
        </div>
      </template>
    </screen-card>
  </screen-grid>
</template>

<script>
import { isMobile } from '@/utils/navigator.util'
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import ScreenFlux from '@/components/Screen/Flux.vue'
import UiButton from '@/components/UI/Button.vue'
import UiDropdown from '@/components/UI/Dropdown.vue'
import SkeletonInput from '@/components/Skeleton/Input.vue'
import DragMixin from '@/mixins/drag.mixin'

export default {
  name: 'FrontOfficeEvent',
  components: {
    ScreenCard,
    ScreenGrid,
    ScreenFlux,
    UiButton,
    UiDropdown,
    SkeletonInput,
  },
  mixins: [DragMixin],
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isUpdating: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentFrontOffice: {
      type: Object,
      required: true,
    },
    currentClient: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isMobile: isMobile(),
      blockToDelete: '',
      slotKeySelect: null,
    }
  },
  computed: {
    slotAvailables() {
      const alreadyUseSlot = this.currentFrontOffice.dynamicContent?.map(event => event.id) || []
      return (
        this.currentFrontOffice?.dynamicContentSlot?.filter(slot => {
          if (!alreadyUseSlot.includes(slot.slug)) {
            return slot
          }
        }) || []
      )
    },
  },
  methods: {
    eventGroupLength(eventGroup) {
      return eventGroup?.events?.length || 0
    },
    isOdd(length) {
      return length % 2
    },
    askForDelete(index) {
      this.blockToDelete = index
    },
    createNewGroup(type) {
      this.$emit('createNewGroup', {
        type: type,
        id: this.slotKeySelect.slug,
        name: this.slotKeySelect.label,
      })
      this.slotKeySelect = null
    },
    removeGroup(type, group) {
      this.$emit('removeGroup', {
        type: type,
        group,
      })
      this.blockToDelete = ''
    },
    addMedia(type, group) {
      this.$emit('editMedia', {
        media: { frontOfficeId: this.currentFrontOffice.id, groupId: group.id },
        parameters: {
          type,
        },
      })
    },
    editMedia(type, group, media) {
      const mediaWithId = {
        ...media,
        frontOfficeId: this.currentFrontOffice.id,
        groupId: group.id,
      }
      this.$emit('editMedia', {
        media: mediaWithId,
        parameters: {
          type,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.front-office-event {
  &--is-editable-draggable {
    &:hover {
      outline: 2px solid $blue-royal;
      box-shadow: 0 0 16px rgba(0, 0, 0, 0.4);
      cursor: grab;
    }

    &__active {
      transition: all $transition-duration-very-fast $transition-effect-default;
      opacity: 0.1;
    }
  }

  &__group {
    margin-bottom: $gutter-tablet;

    @media (min-width: $screen-sm) {
      margin-bottom: 0;
    }

    &__no-data {
      width: 100%;
    }

    &__title-label {
      border-radius: $tile-border-radius;
      padding: 5px;
    }

    &__title-confirm {
      display: flex;
      flex-direction: row;
      width: 100%;

      &__input {
        flex: 1;
      }
      &__confirm {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0 auto;
        &__icon {
          font-size: $font-size-xs;
        }
      }
    }

    &__actions {
      flex: 1;
      margin: $gutter-mobile / 2;
      text-align: center;

      @media (min-width: $screen-sm) {
        margin: $gutter-tablet / 2;
      }

      @media (min-width: $screen-xl) {
        margin: $gutter-desktop / 2;
      }

      &__button {
        margin: $gutter-tablet 0;
        &--demi {
          margin-top: $gutter-tablet;
        }
        &--full {
          border: 1px solid $grey-alto;
          width: 100%;
          height: 100%;
        }
      }
    }

    &__confirm {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 auto $gutter-mobile auto;
      width: 100%;
      text-align: center;
      &__icon {
        font-size: $font-size-xs;
      }
    }
  }

  &__create {
    justify-content: center;
    margin-bottom: 0;
    min-height: 200px;

    &__content {
      width: 100%;
      height: 100%;
      &__slot {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;

        &__button {
          margin: $gutter-mobile auto;
        }
      }
    }
  }
}
</style>

<template>
  <div class="front-offices-id" @scroll.passive="onScroll($event.srcElement, tabsElement)">
    <screen-header
      v-if="!hasError"
      class="front-offices-id__header"
      :title="currentFrontOffice.name"
      :breadcrumbs="breadcrumbs"
      :is-loading="isLoading"
      :right-content="true"
      back-button
    >
      <skeleton-input v-if="isLoading" margin-right="0" />
      <v-popover
        placement="bottom"
        :autoHide="true"
        container=".front-offices-id__header"
        popoverInnerClass="popover-inner no-overflow"
        v-else
      >
        <ui-button
          class="front-offices-id__header__add"
          button="cta"
          icon="add"
          :label="$t(`frontOffices.list.button.add`)"
          :variant="productLine"
          @click="addLocator"
        />
      </v-popover>
      <div class="front-offices-id__header__publish" v-if="isLoading">
        <skeleton-input />
      </div>
      <v-popover
        v-else
        popover-inner-class="front-offices-id__header__popover--inner"
        popover-arrow-class="tooltip-arrow front-offices-id__header__popover--arrow"
        placement="bottom"
        :trigger="isMobile ? 'click' : 'hover'"
        :disabled="isPublishing"
      >
        <ui-button
          class="front-offices-id__header__popover--inner__publish"
          button="cta"
          icon="publish"
          variant="influence"
          :label="$t('frontOffices.id.header.publish.label')"
          :disabled="
            isPublishing ||
            frontOfficeRulesDisabledPublish ||
            !isUserAuthorized([userRoles.superAdmin, userRoles.brandAdmin, userRoles.agency])
          "
          :loader-active="isPublishing"
          @click="!isMobile && publish()"
        />
        <template slot="popover">
          <div class="front-offices-id__header__popover--inner__sync">
            {{ $t('frontOffices.id.header.tooltip.sync') }}
            <div class="front-offices-id__header__popover--inner__sync__date">
              {{ dateLastUpdate }}
            </div>
          </div>
          <!-- PENDING PUBLISH -- Disabled because no endpoint for this time
            <div class="front-offices-id__header__popover--inner__location">
            {{ $t('frontOffices.id.header.tooltip.published') }}
            <span class="front-offices-id__header__popover--inner__location__not-publish">
              {{ publishInfo.numberOfPendingTasks }}
            </span>
            /
            {{ publishInfo.entries }}
          </div>
          -->
          <div class="front-offices-id__header__popover--inner__time">
            {{ $t('frontOffices.id.header.tooltip.estimatedTime') }}
            {{ estimatedTime + $t('frontOffices.id.header.tooltip.seconds') }}
          </div>
          <div v-if="isMobile" class="front-offices-id__header__popover--inner__mobileValidation">
            <span
              @click="publish"
              class="front-offices-id__header__popover--inner__mobileValidation--validate backoffice-icons"
              >success</span
            >
            <span
              v-close-popover
              class="front-offices-id__header__popover--inner__mobileValidation--close backoffice-icons"
              >error</span
            >
          </div>
        </template>
      </v-popover>
      <skeleton-input v-if="isLoading" margin-left="24px" />
      <v-popover
        placement="bottom-end"
        :autoHide="true"
        container=".front-offices-id__header"
        popoverInnerClass="popover-inner no-overflow"
        v-else-if="currentFrontOffice.id"
      >
        <ui-button
          class="front-offices-id__header__cta"
          button="primary"
          variant="influence"
          icon="language"
          :label="currentFrontOffice.localeName"
          :mobile-toggle="true"
          v-if="currentFrontOffice.locales && currentFrontOffice.locales.length > 0"
        />
        <template slot="popover">
          <div class="front-offices-id__header__popoverLang">
            <ui-dropdown
              v-if="currentFrontOffice.locales.length > 0"
              class="front-offices-id__header__popoverLang__dropdown"
              id="dropdown-front-offices"
              v-model="modelLocale"
              :options="currentFrontOfficeLocales"
              :placeholder="$t('frontOffices.id.header.language.placeholder')"
              :dropdown-label="$t('frontOffices.id.header.language.label')"
              label="localeName"
              track-by="localeId"
              :show-label="true"
            />
          </div>
        </template>
      </v-popover>
    </screen-header>
    <screen-error v-if="hasError" :route="{ name: 'FrontOffices' }" :label="$t('frontOffices.id.error.button.back')" />
    <screen-container direction="column" v-else>
      <screen-tabs
        :tabs="tabs"
        :element-fixed="elementFixed"
        :current-tab="currentTab"
        :is-loading="isLoading"
        :current-user="currentUser"
        @mounted="onTabsMounted"
        @click="setCurrentTab"
      />

      <!-- General Section -->
      <front-office-general
        v-if="currentTab === 'general' && currentFrontOffice.id != null"
        :is-loading="isLoading"
        :current-front-office="currentFrontOffice"
        :current-client="currentClient"
        :locales="locales"
        :current-client-locales="currentClientLocales"
        @editSimpleField="editSimpleField"
        @editMedia="editMedia"
        @editLocales="editLocales"
        @editLeadGenerationModes="editLeadGenerationModes"
        @editListSelection="editListSelection"
      />

      <!-- Content Section -->
      <front-office-content
        v-if="currentTab === 'content'"
        :is-loading="isLoading"
        :current-front-office="currentFrontOffice"
        :current-client="currentClient"
        :current-client-feature-list="currentClientFeatureList"
        :front-office-feature-list="featureListDetails"
        @editSimpleField="editSimpleField"
        @editFeatures="editFeatures"
        @editSeoContent="editSeoContent"
        @editMedia="editMedia"
        @updateOrder="updateOrderEvent"
        @editDynamicContentSlot="editDynamicContentSlot"
      />

      <!-- Event Section -->
      <front-office-event
        v-if="currentTab === 'event'"
        :is-loading="isLoading"
        :is-updating="isUpdating"
        :current-front-office="currentFrontOffice"
        :current-client="currentClient"
        @createNewGroup="createNewGroup"
        @editMedia="editMedia"
        @removeGroup="removeGroup"
        @updateOrder="updateOrderEventGroup"
        @dragGroupId="dragGroupId"
      />
    </screen-container>
    <modal-simple-field
      v-if="isEditing && editingModal === 'field'"
      :title="modalTitle"
      :is-updating="isUpdating"
      :data="modal.data"
      :label="modal.label"
      :obj-key="modal.objKey"
      :parameters="modal.parameters"
      @save="saveSimpleField"
      @closed="closedModal"
    />
    <modal-feature
      v-if="isEditing && editingModal === 'feature'"
      :title="modalTitle"
      :is-updating="isUpdating"
      :features="modal.features"
      :featuresDetails="featureListDetails"
      :exceptions="modal.exceptions"
      :active-list="modal.activeList"
      :parameters="modal.parameters"
      @save="saveSimpleField"
      @closed="closedModal"
    />
    <modal-seo
      v-if="isEditing && editingModal === 'seo'"
      :title="modalTitle"
      :is-updating="isUpdating"
      :data="modal.data"
      :label="modal.label"
      :obj-key="modal.objKey"
      :parameters="modal.parameters"
      :seoCharCount="seoCharCount"
      @save="saveSimpleField"
      @closed="closedModal"
    />
    <modal-media
      v-if="isEditing && editingModal === 'media'"
      :title="modalTitle"
      :is-updating="isUpdating"
      :data="modal.data"
      :parameters="modal.parameters"
      @save="updateMedia"
      @add="addMedia"
      @remove="removeMedia"
      @closed="closedModal"
    />
    <modal-locales
      v-if="isEditing && editingModal === 'locales'"
      :title="modalTitle"
      :is-updating="isUpdating"
      :label="modal.label"
      :locales="modal.locales"
      :default-locale="modal.defaultLocale"
      :other-locales="modal.otherLocales"
      :current-client-locales="modal.currentClientLocales"
      @save="saveSimpleField"
      @closed="closedModal"
    />
    <modal-lead-generation-form
      v-if="isEditing && editingModal === 'lead-generation-mode'"
      :title="modalTitle"
      :is-updating="isUpdating"
      :label="modal.label"
      :lead-generation="modal.leadGeneration"
      :lead-generation-modes="leadGenerationModes"
      @save="saveSimpleField"
      @closed="closedModal"
    />
    <modal-list-selection
      v-if="isEditing && editingModal === 'list-selection'"
      :title="modalTitle"
      :is-updating="isUpdating"
      :objectLabelKey="modal.objectLabelKey"
      :localizedTagTexts="modal.localizedTagTexts"
      :localizedDropdownTexts="modal.localizedDropdownTexts"
      :allowedValues="modal.allowedValues"
      :currentValues="modal.currentValues"
      :options="modal.options"
      @save="saveListSelection"
      @closed="closedModal"
    />
    <modal-dynamic-content-slot
      v-if="isEditing && editingModal === 'dynamic-content-slot'"
      :title="modalTitle"
      :is-updating="isUpdating"
      :data="modal.data"
      :parameters="modal.parameters"
      @save="saveSimpleField"
      @closed="closedModal"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { onScroll } from '@/mixins/scroll.mixin'
import { notif } from '@/mixins/notification.mixin'
import mapLocaleMixin from '@/mixins/mapLocale.mixin'
import ScreenHeader from '@/components/Screen/Header.vue'
import ScreenContainer from '@/components/Screen/Container.vue'
import ScreenTabs from '@/components/Screen/Tabs.vue'
import ScreenError from '@/components/Screen/Error.vue'
import UiButton from '@/components/UI/Button.vue'
import UiDropdown from '@/components/UI/Dropdown.vue'
import SkeletonInput from '@/components/Skeleton/Input.vue'
import ModalSimpleField from '@/components/Modal/SimpleField.vue'
import ModalMedia from '@/components/Modal/Media.vue'
import ModalFeature from '@/components/Modal/Feature.vue'
import ModalSeo from '@/components/Modal/Seo.vue'
import ModalLocales from '@/components/Modal/Locales.vue'
import ModalListSelection from '@/components/Modal/ListSelection.vue'
import ModalLeadGenerationForm from '@/components/Modal/LeadGenerationForm.vue'
import ModalDynamicContentSlot from '@/components/Modal/DynamicContentSlot.vue'
import FrontOfficeGeneral from '@/components/FrontOffice/General.vue'
import FrontOfficeContent from '@/components/FrontOffice/Content.vue'
import FrontOfficeEvent from '@/components/FrontOffice/Event.vue'
import { isUserAuthorized, userRoles } from '@/config/permissions.config'
import { leadGenerationModes } from '@/config/location.config'
import { frontOfficeIndice } from '@/services/frontoffice.service'
import { mediaParser } from '@/utils/media.util'
import { formatedDate } from '@/utils/date.util'
import { orderEvent, orderEventGroup } from '@/utils/manage.util'
import { isMobile } from '@/utils/navigator.util'
import { VClosePopover } from 'v-tooltip'

export default {
  name: 'FrontOfficesId',
  components: {
    ScreenHeader,
    ScreenContainer,
    ScreenTabs,
    ScreenError,
    UiButton,
    UiDropdown,
    SkeletonInput,
    ModalSimpleField,
    ModalMedia,
    ModalFeature,
    ModalSeo,
    ModalLocales,
    ModalListSelection,
    ModalDynamicContentSlot,
    FrontOfficeGeneral,
    FrontOfficeContent,
    FrontOfficeEvent,
    ModalLeadGenerationForm,
  },
  mixins: [onScroll, notif, mapLocaleMixin],
  data() {
    return {
      leadGenerationModes,
      isLoading: false,
      isEditing: false,
      isPublishing: false,
      isMobile: isMobile(),
      editingModal: '',
      modal: {
        objKey: null,
        data: null,
        label: null,
        parameters: {
          type: 'text',
          mode: 'input',
          required: false,
          number: null,
        },
      },
      publishInfo: null,
      hasError: false,
      tabsElement: null,
      closePopoverMobile: false,
      dragGroup: {
        start: '',
        end: '',
      },
      userRoles,
      isUserAuthorized,
    }
  },
  directives: {
    VClosePopover,
  },
  beforeMount() {
    if (!this.$route.params.currentTab) {
      this.setCurrentTab('general')
    }
  },
  async mounted() {
    this.isLoading = true
    try {
      Promise.all([
        this.getFrontOffice({ frontOfficeId: this.$route.params.id }),
        this.getClientFeaturesList({ clientId: this.currentClient.id }),
        this.getLocales(),
      ])
      const { data } = await frontOfficeIndice(this.$route.params.id, this.currentClient.id)
      await this.getFrontOfficeFeaturesList()
      this.publishInfo = data
      await this.getFrontOfficeSeoCharCount()
    } catch {
      this.hasError = true
    } finally {
      this.isLoading = false
    }
  },
  computed: {
    ...mapState({
      currentClient: state => state.client.currentClient,
      currentClientFeatureList: state => state.client.currentClientFeatureList,
      currentFrontOffice: state => state.frontoffice.currentFrontOffice,
      locales: state => state.backoffice.locales,
      currentUser: state => state.backoffice.currentUser,
      featureListDetails: state => state.frontoffice.currentFrontOfficeFeatureList,
      seoCharCount: state => state.frontoffice.currentFrontOfficeSeoCharCount,
    }),
    frontOfficeRulesDisabledPublish() {
      return this.currentFrontOffice.algoliaRules?.publish === false
    },
    productLine() {
      return this.$route.meta.productLine || 'data'
    },
    estimatedTimeInSecond() {
      const locationPerLang = this.publishInfo?.entries * this.locales?.length
      let seconds
      if (!locationPerLang || locationPerLang <= 100) {
        seconds = 5
      } else if (locationPerLang <= 400) {
        seconds = 9
      } else if (locationPerLang <= 1000) {
        seconds = 20
      } else if (locationPerLang <= 4000) {
        seconds = 30
      } else if (locationPerLang <= 16000) {
        seconds = 90
      } else if (locationPerLang <= 32500) {
        seconds = 150
      } else {
        seconds = 180
      }
      return seconds
    },
    currentTab() {
      return this.$route.params.currentTab
    },
    dateLastUpdate() {
      return this.currentFrontOffice?.lastAlgoliaImport
        ? formatedDate(this.currentFrontOffice.lastAlgoliaImport, 'LL HH:mm:ss', this.$i18n.locale)
        : this.$t('frontOffices.id.header.tooltip.neverPublish')
    },
    estimatedTime() {
      const minutes = Math.floor(this.estimatedTimeInSecond / 60)
      const seconds = this.estimatedTimeInSecond - minutes * 60

      return `
        ${minutes ? `${minutes} ${this.$t('frontOffices.id.header.tooltip.minutes')}` : ''}
        ${seconds ? Math.round(seconds) : '00'}
      `
    },
    breadcrumbs() {
      return [
        {
          label: this.$t('frontOffices.list.breadcrumb'),
          route: {
            name: 'FrontOffices',
          },
        },
        {
          label: this.currentFrontOffice.name,
          route: {
            name: 'FrontOffice',
            params: {
              id: this.currentFrontOffice.id,
            },
          },
        },
      ]
    },
    tabs() {
      return [
        {
          label: this.$t('frontOffices.id.tab.general'),
          key: 'general',
        },
        {
          label: this.$t('frontOffices.id.tab.content'),
          key: 'content',
        },
        {
          label: this.$t('frontOffices.id.tab.event'),
          key: 'event',
        },
      ]
    },
    modelLocale: {
      get() {
        return this.currentFrontOffice
      },
      set(locale) {
        this.isLoading = true
        this.updateCurrentFrontOfficeLocale(locale).then(() => {
          this.isLoading = false
        })
      },
    },
    currentFrontOfficeLocales() {
      return this.currentFrontOffice.locales || []
    },
    currentClientLocales() {
      const locales = this.currentClient.locales.map(clientLocale =>
        this.locales.find(locale => locale.id === clientLocale)
      )
      return locales
    },
    modalTitle() {
      return this.$t('common.modal.title.frontOffice', {
        frontOffice: this.currentFrontOffice.name,
      })
    },
  },
  methods: {
    ...mapActions({
      getLocales: 'backoffice/getLocales',
      getClientFeaturesList: 'client/getClientFeaturesList',
      getFrontOffice: 'frontoffice/getFrontOffice',
      updateFrontOffice: 'frontoffice/updateFrontOffice',
      updateCurrentFrontOfficeLocale: 'frontoffice/updateCurrentFrontOfficeLocale',
      uploadToolMedia: 'tool/uploadToolMedia',
      removeToolMedia: 'tool/removeToolMedia',
      publishFrontOffice: 'frontoffice/publishFrontOffice',
      updateFrontOfficeSettings: 'frontoffice/updateFrontOfficeSettings',
      getFrontOfficeFeaturesList: 'frontoffice/getFrontOfficeFeaturesList',
      getFrontOfficeSeoCharCount: 'frontoffice/getFrontOfficeSeoCharCount',
    }),
    async removeGroup(params) {
      try {
        const dynamicContent = this.currentFrontOffice.dynamicContent.filter(curr => curr.id !== params.group.id)
        await this.updateFrontOffice({
          [params.type]: dynamicContent,
        })
        this.notificationSuccess('frontOffice', this.currentFrontOffice.name)
      } catch {
        this.notificationError()
      }
    },
    async createNewGroup(params) {
      this.isUpdating = true
      try {
        const group = {
          id: params.id,
          name: params.name,
          events: [],
        }
        await this.updateFrontOffice({
          [params.type]: [
            ...(this.currentFrontOffice.dynamicContent ? this.currentFrontOffice.dynamicContent : []),
            group,
          ],
        })
        this.notificationSuccess('frontOffice', this.currentFrontOffice.name)
      } catch {
        this.notificationError()
      } finally {
        this.isUpdating = false
      }
    },
    addLocator() {
      this.$router.push({ name: 'FrontOfficeCreate' })
    },
    onTabsMounted(element) {
      this.tabsElement = element
    },
    setCurrentTab(tab) {
      if (tab === this.currentTab) return
      this.$router.replace({ ...this.$route, params: { ...this.$route.params, currentTab: tab } })
    },
    getDefaultValue(mode) {
      switch (mode) {
        case 'list':
          return []
      }

      return ''
    },
    editDynamicContentSlot(payload) {
      this.modal.label = this.$t(`frontOffices.id.modal.${payload.objKey}`)
      this.modal.data = this.currentFrontOffice[payload.objKey]
      this.modal.parameters = payload.parameters

      this.isEditing = true
      this.editingModal = 'dynamic-content-slot'
      this.$nextTick(() => {
        this.$modal.show('modal-dynamic-content-slot')
      })
    },
    editSimpleField(payload) {
      const { objKey } = payload
      this.modal.objKey = objKey

      this.modal.label = this.$t(`frontOffices.id.modal.${objKey}`)
      this.modal.data = this.currentFrontOffice[objKey] || this.getDefaultValue(payload.parameters.mode)
      this.modal.parameters = payload.parameters
      this.isEditing = true
      this.editingModal = 'field'
      this.$nextTick(() => {
        this.$modal.show('modal-simple-field')
      })
    },
    editMedia(payload) {
      this.modal.data = payload.media
      this.modal.parameters = payload.parameters
      this.isEditing = true
      this.editingModal = 'media'
      this.$nextTick(() => {
        this.$modal.show('modal-media')
      })
    },
    editLocales(payload) {
      this.modal.label = `frontOffices.id.modal.${payload.objKey}`
      this.modal.data = this.currentClient[payload.objKey]
      this.modal.locales = payload.locales
      this.modal.defaultLocale = payload.defaultLocale
      this.modal.otherLocales = payload.otherLocales
      this.modal.currentClientLocales = payload.currentClientLocales
      this.isEditing = true
      this.editingModal = 'locales'
      this.$nextTick(() => {
        this.$modal.show('modal-locales')
      })
    },
    editListSelection(payload) {
      this.modal.objKey = payload.objKey

      this.modal.objectLabelKey = 'label'
      this.modal.localizedDropdownTexts = { placeholder: '', label: 'Select an option' }
      this.modal.localizedTagTexts = { placeholder: '', label: '' }

      const { currentValues, allowedValuesKey, options, ...restParams } = payload.parameters
      this.modal.currentValues = currentValues
      this.modal.allowedValues = this.currentFrontOffice.algolia[allowedValuesKey]
      this.modal.options = options

      this.modal.parameters = restParams
      this.isEditing = true
      this.editingModal = 'list-selection'
      this.$nextTick(() => {
        this.$modal.show('modal-list-selection')
      })
    },
    editLeadGenerationModes(payload) {
      this.modal.label = this.$t(`frontOffices.id.modal.${payload.objKey}`)
      this.modal.data = this.currentClient[payload.objKey]
      this.modal.leadGeneration = payload.leadGeneration
      this.isEditing = true
      this.editingModal = 'lead-generation-mode'
      this.$nextTick(() => {
        this.$modal.show('modal-lead-generation-mode')
      })
    },
    editFeatures(payload) {
      this.modal.features = payload.features
      this.modal.activeList = payload.activeList
      this.modal.exceptions = payload.exceptions
      this.modal.parameters = payload.parameters
      this.isEditing = true
      this.editingModal = 'feature'
      this.$nextTick(() => {
        this.$modal.show('modal-feature')
      })
    },
    editSeoContent(payload) {
      this.modal.objKey = payload.objKey
      this.modal.label = this.$t(`frontOffices.id.modal.${payload.objKey}`)
      this.modal.data = this.currentFrontOffice[payload.objKey]
      this.modal.parameters = { ...payload.parameters, seoCharCount: this.seoCharCount }
      this.isEditing = true
      this.editingModal = 'seo'
      this.$nextTick(() => {
        this.$modal.show('modal-seo')
      })
    },
    async saveSimpleField(params) {
      this.isUpdating = true
      try {
        await this.updateFrontOffice(params.objKey)
        this.$modal.hide('modal-simple-field')
        this.closedModal()
        this.notificationSuccess('frontOffice', this.currentFrontOffice.name)
      } catch {
        this.notificationError()
      } finally {
        this.isUpdating = false
      }
    },
    async saveListSelection(params) {
      this.isUpdating = true
      try {
        await this.updateFrontOfficeSettings({ [this.modal.objKey]: params })

        this.$modal.hide('modal-list-selection')
        this.closedModal()
        this.notificationSuccess('frontOffice', this.currentFrontOffice.name)
      } catch {
        this.notificationError()
      } finally {
        this.isUpdating = false
      }
    },
    updateMedia(params) {
      this.isUpdating = true
      if (params.type === 'dynamicContent') {
        this.addOrEditGroupMedia(params, true)
      } else {
        this.uploadToolMedia({
          title: params.media.title,
          titleSeo: params.media.titleSeo,
          image: params.image,
          picture: params.media.picture,
          type: params.type,
          name: this.currentFrontOffice.name,
          frontOfficeId: this.currentFrontOffice.id,
        })
          .then(mediaUrl => {
            if (params.type === 'logo') {
              return this.updateFrontOffice({
                logo: mediaUrl,
                logoUrl: params.media.link,
              })
            }
            if (params.type === 'favicon') {
              return this.updateFrontOffice({
                favicon: mediaUrl,
              })
            }
            return this.updateFrontOffice({
              [params.type]: mediaParser(
                this.currentFrontOffice[params.type],
                params.type,
                'edit',
                params.media,
                mediaUrl
              ),
            })
          })
          .then(() => {
            this.$modal.hide('modal-media')
            this.closedModal()
            this.notificationSuccess('frontOffice', this.currentFrontOffice.name)
          })
          .catch(e => this.notificationError(e))
      }
    },
    addOrEditGroupMedia(params, isEdit) {
      const dynamicContent = JSON.parse(JSON.stringify(this.currentFrontOffice[params.type]))
      const index = dynamicContent.findIndex(group => group.id === params.media.groupId)
      this.uploadToolMedia({
        title: params.media.title,
        titleSeo: params.media.titleSeo,
        image: params.image,
        picture: params.media.picture,
        type: params.type,
        name: this.currentFrontOffice.name,
        frontOfficeId: this.currentFrontOffice.id,
        element: isEdit ? params.media.groupId : null,
      })
        .then(mediaUrl => {
          dynamicContent[index].events = mediaParser(
            dynamicContent[index].events,
            params.type,
            isEdit ? 'edit' : 'add',
            params.media,
            mediaUrl
          )
          return this.updateFrontOffice({
            [params.type]: dynamicContent,
          })
        })
        .then(() => {
          this.$modal.hide('modal-media')
          this.closedModal()
          this.notificationSuccess('frontOffice', this.currentFrontOffice.name)
        })
        .catch(e => this.notificationError(e))
    },
    removeGroupMedia(params) {
      const dynamicContent = JSON.parse(JSON.stringify(this.currentFrontOffice[params.type]))
      const index = dynamicContent.findIndex(group => group.id === params.media.groupId)
      this.removeToolMedia({
        picture: params.media.picture,
      })
        .then(() => {
          dynamicContent[index].events = mediaParser(dynamicContent[index].events, params.type, 'delete', params.media)
          return this.updateFrontOffice({
            [params.type]: dynamicContent,
          })
        })
        .then(() => {
          this.$modal.hide('modal-media')
          this.closedModal()
          this.notificationSuccess('frontOffice', this.currentFrontOffice.name)
        })
        .catch(() => this.notificationError())
    },
    updateOrderEvent(params, dataProperty) {
      const obj = {}
      ;(obj[dataProperty] = orderEvent(
        [...this.currentFrontOffice[dataProperty]],
        params.targetId,
        params.currentItemId
      )),
        this.updateFrontOffice(obj).then(() => {
          this.notificationSuccess('frontOffice', this.currentFrontOffice.name)
        })
    },
    updateOrderEventGroup(params) {
      if (this.dragGroup.start === this.dragGroup.end) {
        this.updateFrontOffice({
          dynamicContent: orderEventGroup(
            [...JSON.parse(JSON.stringify(this.currentFrontOffice.dynamicContent))],
            params.targetId,
            params.currentItemId,
            params.eventGroupId
          ),
        }).then(() => {
          this.notificationSuccess('frontOffice', this.currentFrontOffice.name)
        })
      } else {
        this.notificationError('notAuthorize')
      }
    },
    dragGroupId(params) {
      if (params.init === true) {
        this.dragGroup.start = params.id
      } else {
        this.dragGroup.end = params.id
      }
    },
    addMedia(params) {
      this.isUpdating = true
      if (params.type === 'dynamicContent') {
        this.addOrEditGroupMedia(params, false)
      } else {
        this.uploadToolMedia({
          title: params.media.title,
          titleSeo: params.media.titleSeo,
          image: params.image,
          picture: params.media.picture,
          type: params.type,
          name: this.currentFrontOffice.name,
          frontOfficeId: this.currentFrontOffice.id,
        })
          .then(mediaUrl => {
            return this.updateFrontOffice({
              [params.type]: mediaParser(
                this.currentFrontOffice[params.type],
                params.type,
                'add',
                params.media,
                mediaUrl
              ),
            })
          })
          .then(() => {
            this.$modal.hide('modal-media')
            this.closedModal()
            this.notificationSuccess('frontOffice', this.currentFrontOffice.name)
          })
          .catch(e => this.notificationError(e))
      }
    },
    removeMedia(params) {
      this.isUpdating = true
      if (params.type === 'dynamicContent') {
        this.removeGroupMedia(params)
      } else {
        this.removeToolMedia({
          picture: params.media.picture,
        })
          .then(() => {
            return this.updateFrontOffice({
              [params.type]: mediaParser(this.currentFrontOffice[params.type], params.type, 'delete', params.media),
            })
          })
          .then(() => {
            this.$modal.hide('modal-media')
            this.closedModal()
            this.notificationSuccess('frontOffice', this.currentFrontOffice.name)
          })
          .catch(() => this.notificationError())
      }
    },
    closedModal() {
      this.isEditing = false
      this.isUpdating = false
      this.editingModal = ''
    },
    publish() {
      this.isPublishing = true
      this.publishFrontOffice()
        .then(() => {
          this.isPublishing = false
          this.notificationSuccess('frontOffice', this.currentFrontOffice.name, 'publish')
        })
        .catch(() => {
          this.isPublishing = false
          this.notificationError()
        })
    },
  },
}
</script>

<style lang="scss">
.front-offices-id {
  padding-bottom: $button-min-height + $gutter-mobile * 2;

  @media (min-width: $screen-sm) {
    padding-bottom: 0;
  }

  &__header {
    &__cta {
      margin-left: $gutter-mobile;

      @media (min-width: $screen-sm) {
        margin-left: $gutter-tablet;
      }

      @media (min-width: $screen-xl) {
        margin-left: $gutter-desktop;
      }
    }

    &__add {
      margin-right: $gutter-mobile;

      @media (min-width: $screen-sm) {
        margin-right: $gutter-tablet;
      }

      @media (min-width: $screen-xl) {
        margin-right: $gutter-desktop;
      }
    }

    &__popover {
      &--arrow {
        border-color: map-get($generic-color-variants, 'success');
      }

      &--inner {
        box-shadow: 1px 2px 15px 0 rgba(0, 0, 0, 0.16);
        background-color: white;
        padding: 0 0 4px 0;
        max-width: 250px;
        line-height: 1.6;
        font-size: 0.79rem;
        &__sync {
          margin-bottom: 5px;
          background-color: map-get($generic-color-variants, 'success');
          padding: 4px 0;
          text-align: center;
          color: white;
          font-weight: 600;
          &__date {
            padding: 0 5px;
            font-weight: 400;
          }
        }

        &__location {
          padding: 0 $gutter-mobile;
          &__not-publish {
            color: map-get($generic-color-variants, 'blue-cerulean');
          }
        }

        &__time {
          padding: 0 $gutter-mobile;
          color: map-get($generic-color-variants, 'warning');
        }

        &__mobileValidation {
          text-align: center;
          &--validate {
            color: map-get($generic-color-variants, 'success');
            font-size: 1.9rem;
          }

          &--close {
            margin-left: 9px;
            color: map-get($generic-color-variants, 'error');
            font-size: 1.9rem;
          }
        }
      }
    }

    &__publish {
      position: fixed;
      right: $gutter-mobile;
      bottom: $gutter-mobile;
      z-index: 2;

      @media (min-width: $screen-sm) {
        position: relative;
        right: initial;
        bottom: initial;
        margin-left: $gutter-tablet;
      }

      @media (min-width: $screen-xl) {
        margin-left: $gutter-desktop;
      }
    }

    &__popoverLang {
      padding: $gutter-mobile;
      min-width: 280px;

      @media (min-width: $screen-sm) {
        padding: $gutter-tablet;
        min-width: 340px;
      }

      &__dropdown {
        margin-top: $gutter-mobile;

        @media (min-width: $screen-sm) {
          margin-top: $gutter-tablet;
        }

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  &__medias {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: -3px;

    @media (min-width: $screen-sm) {
      margin: -6px;
    }

    &__item {
      display: inline-flex;
      position: relative;
      margin: 3px;
      width: calc(100% / 2 - 6px);

      @media (min-width: $screen-sm) {
        margin: 6px;
        width: calc(100% / 2 - 12px);
      }

      &__img {
        display: block;
        width: 100%;
      }

      &__top {
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        align-items: center;
        justify-content: space-between;
        background-color: rgba(0, 0, 0, 0.2);
        padding: 4px 8px;

        @media (min-width: $screen-sm) {
          padding: 8px;
        }

        &__button {
          margin: $generic-button-offset;
          color: #fff;
        }

        &__label {
          color: #fff;
        }
      }

      &__bottom {
        display: flex;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        align-items: center;
        justify-content: space-between;
        background: rgba(0, 0, 0, 0.2);
        padding: 4px 8px;
        color: #fff;

        @media (min-width: $screen-sm) {
          padding: 8px;
        }

        &__views {
          display: flex;
          align-items: center;

          &__icon {
            margin-right: 8px;
            font-size: 1rem;
          }
        }
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: $gutter-mobile;
    margin-bottom: $gutter-mobile / 2;
    width: 100%;

    @media (min-width: $screen-sm) {
      margin-top: $gutter-tablet;
      margin-bottom: $gutter-tablet / 2;
    }

    @media (min-width: $screen-xl) {
      margin-top: $gutter-desktop;
      margin-bottom: $gutter-desktop / 2;
    }
  }
}
</style>

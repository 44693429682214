<template>
  <screen-grid class="front-office-general">
    <screen-card
      :title="$t('frontOffices.id.general.section.generalData.title')"
      ratio="1-1"
      :overflow-hidden="true"
      display="grid"
      :is-loading="isLoading"
    >
      <template v-slot:body>
        <screen-block
          :title="$t('frontOffices.id.general.section.generalData.label.name')"
          icon="article"
          :has-data="typeof currentFrontOffice.name === 'string'"
          :data="currentFrontOffice.name"
          ratio="1-3"
          :is-loading="isLoading"
          :is-required="true"
          @click="editSimpleField('name', 'text', 'input', true)"
        />
        <screen-block
          :title="$t('frontOffices.id.general.section.generalData.label.gtmCodeClient')"
          icon="attribut"
          :has-data="typeof currentFrontOffice.gtmCodeClient === 'string'"
          :data="currentFrontOffice.gtmCodeClient"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSimpleField('gtmCodeClient', 'text', 'input')"
        />
        <screen-block
          :title="$t('clients.id.general.section.generalData.label.s3Path')"
          icon="link"
          :has-data="typeof currentFrontOffice.s3Path === 'string'"
          :data="currentFrontOffice.s3Path"
          ratio="1-3"
          :is-editable="false"
          :is-loading="isLoading"
        />
        <screen-block
          :title="$t('frontOffices.id.general.section.generalData.label.favicon')"
          icon="photo"
          :has-data="typeof currentFrontOffice.favicon === 'string'"
          :data="currentFrontOffice.favicon"
          ratio="1-3"
          :isImage="true"
          :is-loading="isLoading"
          @click="editImage('favicon', currentFrontOffice.favicon)"
        />
        <screen-block
          :title="$t('frontOffices.id.general.section.generalData.label.distanceUnit')"
          icon="attribut"
          :has-data="typeof currentFrontOffice.distanceUnit === 'string'"
          :data="currentFrontOffice.distanceUnit"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSimpleField('distanceUnit', 'text', 'choice', false, null, ['km', 'mi'])"
        />
        <screen-block
          :title="$t('frontOffices.id.general.section.generalData.label.algoliaIndex')"
          icon="attribut"
          :has-data="typeof currentFrontOffice.algoliaIndex === 'string'"
          :data="currentFrontOffice.algoliaIndex"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSimpleField('algoliaIndex', 'text', 'input')"
        />
        <screen-block
          :title="$t('frontOffices.id.general.section.generalData.label.userForm')"
          icon="article"
          :has-data="typeof currentFrontOffice.leadGeneration === 'string'"
          :data="currentFrontOffice.leadGeneration"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editLeadGenerationModes"
        />
        <screen-block
          :title="$t('frontOffices.id.general.section.generalData.label.tag')"
          icon="attribut"
          :has-data="typeof currentFrontOffice.tag === 'string' && currentFrontOffice.tag !== ''"
          :data="currentFrontOffice.tag"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSimpleField('tag', 'tag', 'input')"
        />
        <screen-block
          :title="$t('frontOffices.id.general.section.generalData.label.logo')"
          icon="photo"
          :has-data="typeof currentFrontOffice.logo === 'string' && currentFrontOffice.logo !== ''"
          :data="currentFrontOffice.logo"
          ratio="1-3"
          :isImage="true"
          :is-loading="isLoading"
          @click="editImage('logo', currentFrontOffice.logo)"
        />
        <screen-block
          :title="$t('frontOffices.id.general.section.generalData.label.url')"
          icon="link"
          :has-data="typeof currentFrontOffice.url === 'string'"
          :data="currentFrontOffice.url"
          ratio="1-3"
          :is-loading="isLoading"
          :is-required="true"
          :is-link="true"
          @click="editSimpleField('url', 'text', 'input')"
        />
        <screen-block
          :title="$t('frontOffices.id.general.section.generalData.label.language')"
          icon="language"
          :has-data="typeof currentFrontOffice.defaultLocaleId === 'number'"
          ratio="1-3"
          :is-loading="isLoading"
          :is-required="true"
          @click="editLocales"
        >
          <template v-slot:data>
            <div>
              {{ $t('frontOffices.id.general.section.generalData.label.defaultLanguage') }}
              {{ mapLocale(locales, currentFrontOffice.defaultLocaleId).localeName }}
            </div>
            <div>
              {{ $t('frontOffices.id.general.section.generalData.label.otherLanguage') }}

              <span v-for="(locale, idx) in otherLocales" :key="`locales-${idx}`">
                {{ locale.localeName }}
                <template v-if="idx < otherLocales - 1">, </template>
              </span>
              <span v-if="otherLocales === 0">
                {{ $t('frontOffices.id.general.section.generalData.label.noData') }}
              </span>
            </div>
          </template>
        </screen-block>
        <screen-block
          :title="$t('frontOffices.id.general.section.generalData.label.collectFormIds')"
          icon="article"
          :has-data="!!currentFrontOffice.collectFormIds && currentFrontOffice.collectFormIds.length > 0"
          :data="currentFrontOffice.collectFormIds"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editList('collectFormIds', 'text', 'list')"
        >
          <template v-slot:data>
            <div v-for="(formId, index) in currentFrontOffice.collectFormIds" :key="`form id ${index}`">
              {{ formId }}
            </div>
          </template>
        </screen-block>
      </template>
    </screen-card>
    <screen-card
      v-if="isUserAuthorized(['sa'])"
      :title="$t('frontOffices.id.algolia.section.algoliaConfiguration.title')"
      display="grid"
      :is-loading="isLoading || isUpdating"
    >
      <template v-slot:body>
        <screen-block
          :title="$t('frontOffices.id.algolia.section.algoliaConfiguration.label.algoliaRules')"
          icon="category"
          :has-data="currentFrontOffice.algoliaRules != null && typeof currentFrontOffice.algoliaRules === 'object'"
          ratio="1-1"
          :is-loading="isLoading"
          @click="editSimpleField('algoliaRules', 'json', 'textarea')"
        >
          <template v-slot:data>
            <code>
              {{ currentFrontOffice.algoliaRules }}
            </code>
          </template>
        </screen-block>
        <div
          v-for="{ title, icon, objKey, data, allowedValuesKey, options } in settingsInformation"
          v-bind:key="objKey"
        >
          <screen-block
            v-if="data != null"
            :title="title"
            :icon="icon"
            :has-data="data != null"
            :data="data.join('<br />')"
            ratio="1-1"
            :is-loading="isLoading || isUpdating"
            @click="editListSelection(objKey, data, allowedValuesKey, options)"
          />
        </div>
      </template>
    </screen-card>
    <screen-card
      v-if="isUserAuthorized(['sa'])"
      :title="$t('frontOffices.id.tracking.section.gaAndSc.title')"
      display="grid"
      :is-loading="isLoading"
    >
      <template v-slot:body>
        <screen-block
          :title="$t('frontOffices.id.tracking.section.gaAndSc.label.gaWebPropertyId')"
          icon="category"
          :has-data="
            typeof currentFrontOffice.gaWebPropertyId === 'string' && currentFrontOffice.gaWebPropertyId !== ''
          "
          :data="currentFrontOffice.gaWebPropertyId"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSimpleField('gaWebPropertyId', 'text', 'input')"
        />
        <screen-block
          :title="$t('frontOffices.id.tracking.section.gaAndSc.label.gaProfile')"
          icon="category"
          :has-data="typeof currentFrontOffice.gaProfile === 'string' && currentFrontOffice.gaProfile !== ''"
          :data="currentFrontOffice.gaProfile"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSimpleField('gaProfile', 'text', 'input')"
        />
        <screen-block
          :title="$t('frontOffices.id.tracking.section.gaAndSc.label.gaAccountId')"
          icon="category"
          :has-data="typeof currentFrontOffice.gaAccountId === 'string' && currentFrontOffice.gaAccountId !== ''"
          :data="currentFrontOffice.gaAccountId"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSimpleField('gaAccountId', 'text', 'input')"
        />
        <screen-block
          :title="$t('frontOffices.id.tracking.section.gaAndSc.label.scProfile')"
          icon="category"
          :has-data="typeof currentFrontOffice.scProfile === 'string' && currentFrontOffice.scProfile !== ''"
          :data="currentFrontOffice.scProfile"
          ratio="1-3"
          :is-loading="isLoading"
          @click="editSimpleField('scProfile', 'text', 'input')"
        />
      </template>
    </screen-card>
  </screen-grid>
</template>

<script>
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import ScreenBlock from '@/components/Screen/Block.vue'
import mapLocaleMixin from '@/mixins/mapLocale.mixin'
import { isUserAuthorized } from '@/config/permissions.config'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'FrontOfficeGeneral',
  components: {
    ScreenGrid,
    ScreenCard,
    ScreenBlock,
  },
  mixins: [mapLocaleMixin],
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentFrontOffice: {
      type: Object,
      required: true,
    },
    currentClientLocales: {
      type: Array,
      required: true,
    },
    currentClient: {
      type: Object,
      required: true,
    },
    locales: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    isUserAuthorized,
    isUpdating: false,
    settingsInformation: [],
    settingsInformationIndexes: {
      searchableAttributesIndex: 0,
      attributesForFacetingIndex: 1,
    },
  }),
  async mounted() {
    this.isUpdating = true
    this.settingsInformation = [
      {
        title: this.$t(`frontOffices.id.algolia.section.algoliaConfiguration.label.searchableAttributes`),
        icon: 'search',
        objKey: 'searchableAttributes',
        allowedValuesKey: 'searchable_fields',
        options: null,
        data: null,
      },
      {
        title: this.$t(`frontOffices.id.algolia.section.algoliaConfiguration.label.attributesForFaceting`),
        icon: 'filter_alt',
        objKey: 'attributesForFaceting',
        allowedValuesKey: 'faceting_fields',
        options: ['Searchable', 'Filter only', 'Not searchable'],
        data: null,
      },
    ]
    try {
      await this.getFrontOfficeSettings()
    } finally {
      this.isUpdating = false
    }
  },
  computed: {
    ...mapState({
      currentFrontOfficeSettings: state => state.frontoffice.currentFrontOfficeSettings,
    }),
    otherLocales() {
      return this.currentFrontOffice.locales.filter(
        locale => locale.localeId !== this.currentFrontOffice.defaultLocaleId
      )
    },
  },
  watch: {
    currentFrontOfficeSettings: function () {
      const { searchableAttributes, attributesForFaceting } = this.currentFrontOfficeSettings
      const { searchableAttributesIndex, attributesForFacetingIndex } = this.settingsInformationIndexes
      this.settingsInformation[searchableAttributesIndex].data = searchableAttributes
      this.settingsInformation[attributesForFacetingIndex].data = attributesForFaceting
    },
  },
  methods: {
    ...mapActions({
      getFrontOfficeSettings: 'frontoffice/getFrontOfficeSettings',
    }),
    editSimpleField(objKey, type, mode, required = false, number = null, options = []) {
      this.$emit('editSimpleField', {
        objKey,
        parameters: {
          type,
          mode,
          required,
          number,
          options,
        },
      })
    },
    editImage(type, media) {
      this.$emit('editMedia', {
        media: {
          id: -1,
          picture: media,
          link: type === 'logo' ? this.currentFrontOffice.logoUrl : null,
        },
        parameters: {
          type,
        },
      })
    },
    editLeadGenerationModes() {
      this.$emit('editLeadGenerationModes', {
        objKey: 'leadGeneration',
        leadGeneration: this.currentFrontOffice.leadGeneration,
      })
    },
    editLocales() {
      this.$emit('editLocales', {
        objKey: 'locales',
        defaultLocale: this.currentFrontOffice.defaultLocaleId,
        otherLocales: this.otherLocales,
        locales: this.filteredLocales(),
        currentClientLocales: this.currentClientLocales,
      })
    },
    filteredLocales() {
      const locales = [...this.locales]
      locales.push(this.locales.find(locale => locale.id === this.currentFrontOffice.defaultLocaleId))
      return locales
    },
    editList(objKey, type, mode, listKey = null) {
      this.$emit('editSimpleField', {
        objKey,
        parameters: {
          type,
          mode,
          listKey,
        },
      })
    },
    editListSelection(objKey, currentValues, allowedValuesKey, options) {
      this.$emit('editListSelection', {
        objKey,
        parameters: {
          allowedValuesKey,
          currentValues,
          options,
          required: false,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.front-office-general {
  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: $gutter-mobile;
    margin-bottom: $gutter-mobile / 2;
    width: 100%;

    @media (min-width: $screen-sm) {
      margin-top: $gutter-tablet;
      margin-bottom: $gutter-tablet / 2;
    }

    @media (min-width: $screen-xl) {
      margin-top: $gutter-desktop;
      margin-bottom: $gutter-desktop / 2;
    }
  }
}
</style>

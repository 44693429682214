<template>
  <modal
    name="modal-dynamic-content-slot"
    :title="title"
    :has-apply="true"
    :is-updating="!currentSlotHaveLength"
    @save="updateModelData"
    @closed="closed"
  >
    <template v-slot:container>
      <div class="modal-dynamic-content-slot__title">
        <span @click="clearSlot(false)" :class="{ 'modal-dynamic-content-slot__title__event': createdMode }">
          {{ $t('modal.dynamicContentSlot.update.title') }}
        </span>
        <span>{{ $t('modal.dynamicContentSlot.separator.or') }}</span>
        <span @click="clearSlot(true)" :class="{ 'modal-dynamic-content-slot__title__event': !createdMode }">{{
          $t('modal.dynamicContentSlot.create.title')
        }}</span>
      </div>
      <div v-if="!createdMode" class="modal-dynamic-content-slot__block">
        <ui-dropdown
          class="modal-dynamic-content-slot__block__input"
          :no-absolute="true"
          :value="currentSlotHaveLength ? modelData[indexSlotEdited] : null"
          :options="parameters.options"
          :placeholder="$t('modal.dynamicContentSlot.dropdown.label')"
          label="label"
          track-by="id"
          :dropdown-label="$t('modal.dynamicContentSlot.dropdown.label')"
          :show-label="true"
          @input="setCurrentSlot"
        />
        <div v-if="currentSlotHaveLength" @click="deleteSlot" class="modal-dynamic-content-slot__block__delete">
          {{ $t('modal.dynamicContentSlot.delete') }}
        </div>
      </div>

      <div
        class="modal-dynamic-content-slot__data"
        :class="{ 'modal-dynamic-content-slot__data--margin-top': !createdMode }"
      >
        <ui-input
          id="input-search"
          :label="$t('modal.dynamicContentSlot.label')"
          v-model="currentSlot.label"
          class="required-asterisk modal-dynamic-content-slot__block__input"
          :disabled="!createdMode && !currentSlotHaveLength"
        />
        <ui-input
          id="input-slug"
          :label="$t('modal.dynamicContentSlot.slug')"
          v-model="currentSlot.slug"
          class="required-asterisk modal-dynamic-content-slot__block__input"
          :disabled="!createdMode && !currentSlotHaveLength"
        />
      </div>
    </template>
  </modal>
</template>

<script>
import UiDropdown from '@/components/UI/Dropdown.vue'
import UiInput from '@/components/UI/Input.vue'
import { required } from 'vuelidate/lib/validators'

const initCurrentSlot = () => ({
  label: '',
  slug: '',
})

export default {
  name: 'ModalDynamicContentSlot',
  components: {
    UiInput,
    UiDropdown,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    data: {
      type: [String, Object, Array, Number],
      required: true,
    },
    parameters: {
      type: Object,
      required: false,
      default: () => ({
        options: [],
      }),
    },
    isUpdating: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      modelData: [],
      createdMode: false,
      currentSlot: initCurrentSlot(),
    }
  },
  mounted() {
    this.modelData = JSON.parse(JSON.stringify(this.data))
  },
  computed: {
    currentSlotHaveLength() {
      return this.currentSlot.label.length > 0 || this.currentSlot.slug.length > 0
    },
    indexSlotEdited() {
      return this.modelData.findIndex(slot => slot.id === this.currentSlot.id)
    },
    dropdownOptions() {
      return JSON.parse(JSON.stringify(this.parameters.options))
    },
  },
  methods: {
    deleteSlot() {
      this.modelData.splice(this.indexSlotEdited, 1)
      this.updateModelData()
    },
    setCurrentSlot(slot) {
      this.currentSlot = JSON.parse(JSON.stringify(slot))
    },
    updateModelData() {
      if (this.createdMode) {
        this.currentSlot.id = Math.random().toString(36).substring(2, 15)
        this.modelData.push(this.currentSlot)
      } else {
        this.modelData[this.indexSlotEdited] = this.currentSlot
      }
      this.save()
    },
    save() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$emit('save', {
          objKey: { dynamicContentSlot: this.modelData },
        })
        this.$v.$reset()
      }
    },
    closed() {
      this.$emit('closed')
    },
    clearSlot(createSlot) {
      this.currentSlot = initCurrentSlot()
      this.createdMode = createSlot
    },
  },
  validations() {
    return {
      currentSlot: {
        label: {
          required,
        },
        slug: {
          required,
        },
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.modal-dynamic-content-slot {
  &__title {
    margin-bottom: 16px;
    font-weight: 600;

    &__event {
      transition: opacity $transition-duration-fast $transition-effect-default;
      cursor: pointer;
      text-decoration: underline;
      color: $blue-dodger;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: $gutter-mobile;

    &__delete {
      transition: opacity $transition-duration-fast $transition-effect-default;
      margin: 4px auto 0 8px;
      cursor: pointer;
      color: $red-pomegranate;
      font-size: $font-size-sm;

      &:hover {
        opacity: 0.8;
      }
    }

    &__input {
      padding-top: 0;
      width: 100%;
    }

    &__manage {
      display: flex;
      margin-left: $gutter-mobile;
    }
  }

  &__data {
    display: flex;
    flex-direction: row;
    grid-gap: $gutter-mobile;
    margin-top: 8px;

    &--margin-top {
      margin-top: calc(#{$gutter-mobile} * 2);
    }
  }
}
</style>
